.grid .header-row.row {
  display: flex;
}

.header-row.row {
  display: flex;
}

.check {
  flex: 0.6;
}

.groupname.sortable {
  flex: 1;
}

.location.sortable {
  flex: 1;
}

.grouptype.sortable {
  flex: 1;
}

.groupid.sortable {
  flex: 1.4;
}

.items {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.row .check {
  flex: 0.3;
}

.row .location {
  flex: 1;
}

.row .grouptype {
  flex: 1;
}

.row .groupid {
  flex: 1.1;
}

.row .alerts {
  flex: 0.9;
}

.row .send {
  flex: 0.2;
}

.row > span.actions {
  flex: 0.2;
  overflow: visible;
}

.create-group-button {
  background-color: transparent;
  border: 1px solid #616b7d;
  color: #616b7d;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.create-group-button:hover {
  background-color: transparent;
  color: #616b7d;
}

.group-members .actions-menu .popover {
  text-align: left;
  min-width: 116px;
  margin-top: -16px;
  // margin-right: 500px;
}

.btn-outline {
  display: block;
}

.group-members-card .group-members-grid {
  margin-top: 30px;
}

.grid {
  margin-top: 30px;
}

.menu-item {
  line-height: 40px;
  padding: 0 16px;
  white-space: nowrap;
}

.menu-item:hover {
  background: rgba(var(--primary-hilight), 1);
}
