@import "vars.scss";
.messages-card {
  margin: 0;
}

.page-layout-message {
  .page-layout {
    height: 100vh;
  }
  .bottom-nav-bar {
    position: fixed;
  }
}

.messages-search-header {
  padding: 24px 20px;
  display: flex;
  justify-content: space-between;
  @media ($IsMobile) {
    flex-direction: column;
    padding: 16px 20px;
  }
  &-tabs {
    @media ($IsMobile) {
      margin-bottom: 16px;
    }
  }
  &-filter {
    @media ($IsMobile) {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
    &-btn-actions {
      @media ($IsMobile) {
        height: 39px;
      }
    }
  }
}

.close-primary-icon {
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  color: $TextSecondary;
}
.bulk-delete-modal {
  .modal {
    padding: 0;
  }
  .modal-content {
    padding: 0;
    max-width: 400px;
  }
}
.bulk-delete-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 16px 16px;
  span {
    color: $TextColor;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
}
.bulk-delete-modal-content {
  padding: 16px 16px 40px 16px;
}
.bulk-delete-modal-footer {
  padding: 0 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
  .warning {
    margin-left: 8px;
  }
}
.messages-bulk-actions-bar {
  display: flex;
  align-items: center;
  padding: 12px 16px 12px 20px;
  &-selected {
    color: #1863cc;
  }
  .vr-divider {
    border-left: 1px solid #e0e0e2;
    margin-left: 8px;
  }
  .bulk-delete-btn {
    margin-left: 16px;
    min-width: 72px;
    background-color: $ContentBackground;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: $TextSecondary;
    border-color: $OutlineButtonBorder;
  }
}
.alert-type-btn-mobile {
  display: flex;
  height: 16px;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  margin: 0 8px;

  border-radius: 12px;
  border: none;
  background-color: $OldGrayMedium;
  min-width: fit-content;
  span {
    color: $TextColor;
    margin: 0;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    i {
      margin-right: 8px;
      color: $TextSecondary;
      text-align: center;
      font-size: 12px;
      font-weight: 900;
      line-height: 100%;
    }
  }
}
.check-body-details-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  margin-top: 4px;
  &-header {
    display: flex;
    flex-direction: column;
    span {
      color: #6b6b6b;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
    audio {
      @media ($IsMobile) {
        height: 20px;
        width: 276px;
      }
    }
    .loader {
      height: 22px;
      width: 22px;
    }
  }
  .mobile-action-icon {
    color: #757575;
    font-size: 16px;
    font-weight: 900;
    line-height: 100%;
  }
}
.mobile-scheduled-alert-type-btn {
  display: flex;
  height: 20px;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  border-radius: 12px;
  border: none;
  background-color: #f9e3d8;
  min-width: fit-content;
  i {
    color: #9f522e;
    text-align: center;
    font-size: 12px;
    font-weight: 900;
    line-height: 100%;
  }
}
.mobile-draft-alert-type-btn {
  display: flex;
  height: 20px;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  border-radius: 12px;
  border: none;
  background-color: #f3d9d9;
  min-width: fit-content;
  i {
    color: #a83939;
    text-align: center;
    font-size: 12px;
    font-weight: 900;
    line-height: 100%;
  }
}
.messages-grid {
  .grid {
    margin: 0;
    @media ($IsMobile) {
      top: 275px;
      bottom: 0;
    }
    .row {
      display: flex;
      flex-direction: column;
      .check {
        .check-row {
          display: flex;
          align-items: end;
          padding: 16px;
          @media ($IsMobile) {
            padding: 16px 16px 0 16px;
            align-items: center;
          }
          .check-title {
            @media ($IsDesktop) {
              display: flex;
              align-items: center;
            }
          }
        }
        .check-body {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          margin-left: 16px;
          @media ($IsMobile) {
            margin-left: 8px;
          }
          .check-body-name {
            color: $TextColor;
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            @media ($IsMobile) {
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
              display: flex;
              justify-content: space-between;
            }
            &-header {
              @media ($IsMobile) {
                display: flex;
                flex-wrap: wrap;
              }
            }
          }
          .check-body-details {
            display: flex;
            align-items: center;
            .msg-subject {
              margin-right: 24px;
            }
            .scheduled-alert-type-btn {
              display: flex;
              height: 20px;
              padding: 2px 8px;
              justify-content: center;
              align-items: center;
              gap: 4px;
              margin-right: 8px;
              border-radius: 12px;
              border: none;
              background-color: #f9e3d8;
              span {
                color: #9f522e;
                margin: 0;
                font-size: 11px;
                font-weight: 600;
                line-height: 16px;
                i {
                  margin-right: 8px;
                  color: #9f522e;
                  text-align: center;
                  font-size: 12px;
                  font-weight: 900;
                  line-height: 100%;
                }
              }
            }
            .draft-alert-type-btn {
              display: flex;
              height: 20px;
              padding: 2px 8px;
              justify-content: center;
              align-items: center;
              gap: 4px;
              margin-right: 8px;
              border-radius: 12px;
              border: none;
              background-color: #f3d9d9;
              span {
                color: #a83939;
                margin: 0;
                font-size: 11px;
                font-weight: 600;
                line-height: 16px;
                i {
                  margin-right: 8px;
                  color: #a83939;
                  text-align: center;
                  font-size: 12px;
                  font-weight: 900;
                  line-height: 100%;
                }
              }
            }
            .alert-type-btn {
              display: flex;
              height: 20px;
              padding: 4px 12px;
              justify-content: center;
              align-items: center;
              margin-right: 8px;
              border-radius: 12px;
              border: none;
              background-color: $OldGrayMedium;
              min-width: fit-content;

              span {
                color: $TextColor;
                margin: 0;
                font-size: 11px;
                font-weight: 600;
                line-height: 16px;
                i {
                  margin-right: 8px;
                  color: $TextSecondary;
                  text-align: center;
                  font-size: 12px;
                  font-weight: 900;
                  line-height: 100%;
                }
              }
            }

            span {
              margin-right: 12px;
              white-space: nowrap;
              color: $TextSecondary;
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
            }
          }
        }
        .check-body-date {
          color: $TextSecondary;
          text-align: right;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          @media ($IsMobile) {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
// .profile-image-container {
//   position: relative;
//   margin-top: 20px; // Push the image into the curved background
//   display: inline-block;
// for profile image
.profile-image {
  margin-left: 12px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
}
// }
