.circular-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; // Full screen height
}

.loader {
  border: 4px solid transparent; /* No background color */
  border-top-color: #1863cc; /* Blue color for the circular-progress */
  border-radius: 50%;
  width: 44px; /* Width of the circular-progress */
  height: 44px; /* Height of the circular-progress */
  animation: spin 1s linear infinite; /* Spin animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
