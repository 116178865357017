@import "vars.scss";
.bottom-nav-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #292c2d;
  color: #ffffff;
  height: 60px;
  @media ($IsMobile) {
    position: sticky;
  }

  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    width: 97.5px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 10px;

    &.active {
      background-color: #222525;
      border-bottom: 3px solid #ffffff;
    }

    i {
      font-size: 16px;
    }

    span {
      font-size: 10px;
    }
  }
}
