@import "vars.scss";
.my-dashboard {
  padding: 24px;
  display: flex;
  @media ($IsMobile) {
    flex-direction: column;
    padding: 0;
  }
  @media ($IsTablet) {
    flex-direction: column;
    padding: 0;
  }
  position: relative;
  z-index: 1;
}

.overlay {
  position: relative;
}
.mobile-title {
  display: flex;
  align-items: center;
  &-icon-div {
    padding: 10px;
    margin-right: 8px;
  }
  .clicked {
    padding: 10px;
    margin-right: 8px;
    background: #ededed;
  }

  &-progress-icon {
    i {
      font-size: 16px;
      font-weight: 900;
      color: #757575;
    }
  }
}
.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #4e5c690a;
  z-index: -1; /* Behind the content */
}

.disabled {
  pointer-events: none; /* Disable pointer events */
  opacity: 0.6; /* Make it look disabled */
}
.sidebard-scrollbar {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
  width: 100%;
  margin-left: 24px;
  margin-right: 0;
  overflow-x: hidden;
}
.sidebard-scrollbar::-webkit-scrollbar {
  display: none;
}
.dashboard-mobile-tooltip {
  .gui-tooltip {
    width: max-content;
    background-color: #fff;
    color: #6b6b6b;
    position: absolute;
    margin: 0;
    &[x-placement="bottom"]::before {
      content: "";
      position: absolute;
      border-width: 0 5px 5px 5px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
