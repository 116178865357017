@import "vars.scss";

.tools {
  margin-left: auto;
}
.people {
  .bottom-nav-bar {
    position: fixed;
  }

  .grid-mobile-footer {
    position: absolute;
    bottom: auto;
  }
  .filter-popover .popover {
    @media ($IsDesktop) {
      top: calc(100% + 24px);
      left: calc(100% - 101px);
    }
    top: calc(100% + 10px);
    left: calc(100% - 160px);
  }
}

.mobile-filter-bar-with-btns {
  display: flex;
  justify-content: space-around;
  height: 10vh;
  padding-top: 16px;
  align-items: center;

  button {
    height: fit-content;
  }
}

/* Grid */
.people .grid {
  padding-bottom: 14px;
  font-size: $FontSizeSmall;

  @media ($IsDesktop) {
    margin: 0 24px;
  }
}

.people .grid .row {
  display: flex;

  &:hover:not(.header-row) {
    background: $GridRowHover;
    border-bottom-color: $GridRowHoverBorder;
  }
}
.people .grid .row > span.check {
  flex: 0 0 44px;
  text-align: center;
  font-size: $FontSizeSmall;
}
.people .grid .row > span.icon-col {
  flex: 0 0 60px;
}
.people .grid .row > span.firstname {
  flex: 1 1 100px;
}
.people .grid .row > span.lastname {
  flex: 1 1 100px;
}
.people .grid .row > span.uniqueid {
  flex: 1 1 100px;
}
.people .grid .row > span.address {
  flex: 1 1 100px;
}
.people .grid .row > span.phonecode {
  flex: 1 1 100px;
}
.people .grid .row > span.username {
  flex: 1 1 100px;
}
.people .grid .row > span.usertype {
  flex: 0 0 125px;
}
.people .grid .row > span.priority {
  flex: 1 1 100px;
}
.people .grid .row > span.send {
  flex: 0 0 60px;
  padding: 0;
}
.people .grid .row > span.actions {
  flex: 0 0 48px;
  overflow: initial;
}

.people .icon {
  display: inline-block;
  width: 32px;
  line-height: 32px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-radius: 16px;
  background: $TagBackground;
}

.people .send i {
  font-weight: 900;
}

.people .tag.high-priority {
  color: $PrimaryColor;
  background: $PrimaryMuted;
}

/* Actions menu */
.people .actions > i {
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
}

.people .actions-menu .popover {
  top: -10px;
  right: -10px;
}

/* User type tags */
.people .tag.admin {
  color: $PrimaryColor;
  background: $PrimaryMuted;
}

/* Edit Person Modal */
.edit-person-modal .modal-content {
  width: 520px;
}

.edit-person-modal .gui-textbox {
  margin-bottom: 24px;
}

.edit-person-modal .gui-textbox input {
  width: 100%;
}

/* Mobile */
@media ($IsMobile) {
  .grid .item .info h6 {
    display: flex;
    margin-top: 13px;
  }
  .grid .item h6 span {
    flex: 1 1 auto;
    margin-top: 3px;
  }
  .grid .item h6 .tag {
    flex: 0 0 auto;
    margin-right: 12px;
    margin-top: 0;
  }
}
