@import "vars.scss";
.tabs-container {
  display: flex;
  background-color: $TopBarUserIconBackground;
  padding: 4px;
  border-radius: 8px;
  @media ($IsMobile) {
    padding: 4px;
    align-items: center;
    border-radius: 6px;
    max-width: 300px;
  }
}

.tab-item {
  padding: 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: $TextSecondary;
  transition: all 0.1s ease-in-out;
  @media ($IsMobile) {
    height: 26px;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
  }
}

.tab-item.active {
  background-color: $ContentBackground;
  color: $TextColor;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  @media ($IsMobile) {
    font-size: 12px;
  }
}
