.dropdown-toggle-comm {
  // width: 207px;
  width: 260px;
  height: 40px;
  background-color: #ffffff;
  color: #6b6b6b;
  text-align: left;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  padding-top: 9px;
  margin-left: 16px;
  z-index: 2147483647;
}

.dropdown-toggle-comm .btn {
  width: 300px;
  height: 40px;
  background-color: #ffffff;
  color: #6b6b6b;
  text-align: left;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  padding-top: 9px;
}

.dropdown-container-comm {
  width: 260px;
  height: auto;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 2px 4px 8px 0px #42434a33;
}

.dropdown-container-activity {
  // width: 280px;
  width: 260px;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 2px 4px 8px 0px #42434a33;
}

.all-communication-btn {
  background: transparent;
  color: #363636;
  padding-left: 12px;
  font-family: "Red Hat Text";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-style: none;
  padding-top: 16px;
}
