// Note:

// This file should only contain scss variables
// Any actual css in here will be duplicated many times
// Since many components' css include this file
// Global css should go in app.scss

// Global
$FontSizeLarge: var(--font-size-large); // 20px
$FontSizeNormal: var(--font-size-normal); // 16px
$FontSizeSmall: var(--font-size-small); // 14px
$FontSizeTiny: var(--font-size-tiny); // 12px

$PrimaryColor: rgba(var(--primary-color), 1); // #1D63C6;
$DimPrimaryColor: rgba(var(--text-dim-primary), 1); // #A6A6A6;
$DimHover: rgba(var(--text-dim-hover), 1); // #EDEDED
$PrimaryMuted: rgba(var(--primary-muted), 1); // #E6EFFE
$Warning: rgba(var(--warning), 1); // #C54443
$Error: rgba(var(--warning), 1); // #C54443
$TextSecondary: rgba(var(--text-secondary), 1); // #6B6B6B
$PrimaryHilight: rgba(var(--primary-hilight), 1); // #F1F6FC
$IconGray: rgba(var(--icon-gray), 1); // #757575
$OldGrayMedium: rgba(var(--border-color-5), 1); // #E1E1E1

// Page
$PageBackground: rgba(var(--page-background), 1); // #F3F3F7
$ContentBackground: rgba(var(--content-background), 1); // #FFFFFF
$TextColor: rgba(var(--text-primary), 1); // #363636
$TextColorInverted: rgba(var(--text-color-inverted), 1); // #FFFFFF
$SmallRoundedCorners: var(--small-rounded-corners); // 4px
$LargeRoundedCorners: var(--large-rounded-corners); // 8px
$XLargeRoundedCorners: var(--x-large-rounded-corners); // 10px

// Navigation
$TopBarBackground: rgba(var(--top-bar-background), 1); // #0F3E80
$TopBarText: rgba(var(--text-color-inverted), 1); // #FFFFFF
$TopBarUserIconBackground: rgba(var(--stroke-low), 1); // #EDEDED
$TopBarHeight: 65px;
$TopBarUserIconText: rgba(var(--text-primary), 1); // #363636
$SideBarText: rgba(var(--text-color-inverted), 0.8);
$SideBarBackground: rgba(var(--sidebar-background), 1); // #292C2D
$SideBarSubItemBackground: rgba(
  var(--sidebar-subitem-background),
  1
); // #313435
$SideBarHoverText: rgba(var(--text-color-inverted), 1); // #FFFFFF

// Page Layout
$HeaderShadow: 0px 1px 3px 0px rgba(var(--box-shaodw-color), 0.1);
$CardShadow: 0; //0px 2px 16px 0px rgba(78, 92, 105, 0.04);

// Controls
$InputBorderColor: rgba(var(--border-color), 1); // #B5B5B5
$OutlineButtonBorder: rgba(var(--border-color-2), 1); // #A6A6A6
$LightBorder: rgba(var(--border-color-4), 1); // #ECECEE
$InputBackgroundColor: rgba(var(--content-background), 1); // #FFFFFF
$SecondaryButtonHover: rgba(var(--secondary-button-hover), 1); // #EFF1F6

// Grid
$GridHeaderBottomBorder: rgba(var(--border-color-3), 1); // #D2D2D2
$GridRowHover: rgba(var(--primary-hilight), 1); // #F1F6FC
$GridRowHoverBorder: rgba(var(--dim-active), 1); // #D2E0F3

// Tags
$TagBackground: rgba(var(--border-color-4), 1); // #ECECEE

// Old MB Design System Colors
$GuiWhite: #ffffff;
$GuiBlack: #353c43;
$GuiBlack40: #aeb1b4;
$GuiButtonBlack: #616b7d;
$GuiButtonBlackOpacity70: rgba($GuiButtonBlack, 0.7);
$GuiPrimary: #176bfb;
$GuiPrimaryHover: #4685f2;
$GuiPrimaryActive: #074bbf;
$GuiGrayMedium: #ececee;
$GuiRed: #c84040;
$GuiRedHover: #d36666;
$GuiRedActive: #b23434;
$GuiRedMuted: #f9ebeb;
$GuiCommunications: #6ea780;
$GuiSelection: #f0f5ff;
$GuiBlackOpactiy40: rgba($GuiBlack, 0.4);
$GuiGrayDark: #ccced0;
$GuiGreen: #3ca455;
$GuiGreenHover: #63b677;
$GuiGreenDark: #2f8845;
$GuiGreenMutedShade: #f1f9f3;
$GuiGold: #f0b800;
$GuiGoldHover: #ffcc14;
$GuiInputDefaultBorder: #e0e0e2;
$GuiSecondaryButtonActive: #dee1e8;

/* Project Colors */
$MessagingThemeColor: #6ea881;
$PageBackground: #f3f3f7;
$AttachmentBackground: #e7e7e9;
$EmergencyBackground: #f0d6d6;
$VoiceTabsBackground: #ebebee;
$DarkerTextColor: #110f24;
$EmailSubjectBGColor: #f8f8fa;
$SearchIconTextColor: #aeb1b4;
$HelpBGColor: #fafafa;
$BlackoutColor: rgba(0, 0, 0, 0.5);
$LighterBlackoutColor: rgba(0, 0, 0, 0.1);
$WhiteoutColor: rgba($GuiWhite, 0.65);
$LighterGrayBorderColor: #f2f2f2;
$SidebarHover: #20282c;
$SidebarItemSelected: #1e282c;
$SidebarSubItemHoverBackground: #2a3237;
$ToolbarMobileBackground: #eeeeee;

/* Menu */
$SidebarWidth: 250px;
$SidebarCollapsedWidth: 54px;

/* Spacing */
$ContentLeftPadding: 24px;

/* Other */
$IsMobile: "max-width: 767px";
$IsTablet: "max-width:1200px";
$IsDesktop: "min-width: 768px";
$BoxShadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
$ModalBoxShadow: 2px 4px 8px rgba(66, 67, 74, 0.2);
$AnimationLength: 0.25s;

/*Border*/
$ButtonBorder: 1px solid var(--color-gray-border);

/* Z-Indexes */
$HeaderZ: 100;
$BlackoutZ: 1000;
$SidebarZ: 1001;
$ModalZ: 1002;
$ToastZ: 2000;

// Library Items
$ImageItemShadow: 1px 2px 4px rgba(52, 52, 52, 0.13);
$ImageItemBorderRadius: 5px;

@mixin guiInput {
  border-radius: 4px;
  height: 2.5em;
  font-weight: 400;
  border: 1px solid $InputBorderColor;
  border-radius: 4px;
  padding: 0.25em 1em;
  outline: 0;
  background: $InputBackgroundColor;
  transition: border-color 0.3s;
  font-size: 1em;
  transition: border-color linear $AnimationLength;
}

@mixin guiInputFocused {
  border-color: $PrimaryColor;
}

@mixin guiLabel {
  cursor: text;
  user-select: none;
  position: absolute;
  left: 2px;
  top: 0.25em;
  background: $InputBackgroundColor;
  padding: 0.25em 1em;
  pointer-events: none;
  transition: font-size $AnimationLength, top $AnimationLength,
    left $AnimationLength, padding $AnimationLength, color $AnimationLength;
}

@mixin guiLabelFocused {
  color: $PrimaryColor;
}

@mixin guiLabelWithValue {
  font-size: 0.75em;
  top: -0.8em;
  left: 0.8em;
  padding: 1px 5px;
}

@mixin MenuItem {
  background: $SideBarBackground;
  color: $SideBarText;
  border-left: 2px solid $SideBarBackground;
}

@mixin MenuItemHover {
  background: #222525;
  color: $SideBarHoverText;
  border-left: 2px solid var(--color-sidebar-hover);
}

@mixin MenuItemSelected {
  background: #222525;
  color: $SideBarHoverText;
  border-left: 2px solid $SideBarHoverText;
}

@mixin MenuSubItem {
  @include MenuItem;
  background: $SideBarSubItemBackground;
}

@mixin MenuSubItemHover {
  background: #222525;
  color: $SideBarText;
  border-left: 2px solid var(--color-sidebar-hover);
}

@mixin MenuSubItemSelected {
  @include MenuItemSelected;
  background: #222525;
}

@mixin contentTitle {
  font-weight: 400;
  font-size: 20px;
  margin: 0;
  padding: 20px 0 20px $ContentLeftPadding;
  background: $ContentBackground;
  overflow: hidden;
}

@mixin errorMsg {
  display: block;
  color: $Warning;
  font-size: 0.8em;
  margin: 0.1em 0 0 0.1em;
}

@mixin disableHilighting {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@mixin enableHilighting {
  user-select: initial;
  -webkit-touch-callout: initial;
  -webkit-tap-highlight-color: initial;
}

@mixin fadeIn {
  animation: 300ms appear;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
