@import "vars.scss";

.filter-popover .popover {
  top: calc(100% + 24px);
  left: calc(100% - 73px);
}
.mobile-filter-bar-btns {
  display: flex;
  justify-content: flex-end;
  height: 5vh;
  align-items: center;
  margin-right: 30px;
  .filter-popover .popover {
    left: calc(100% - 167px);
    top: calc(100% + 10px);
  }
}
.groups {
  .bottom-nav-bar {
    position: fixed;
  }
  .grid-mobile-footer {
    position: absolute;
    bottom: auto;
  }
}
/* Groups List */
.groups .grid {
  padding-bottom: 14px;
  font-size: $FontSizeSmall;

  @media ($IsDesktop) {
    margin: 0 24px;
  }
}

.groups .grid .row {
  display: flex;

  &:hover:not(.header-row) {
    background: $GridRowHover;
    border-bottom-color: $GridRowHoverBorder;
  }
}

/* Row Sizes */
.groups .grid .row > span.check {
  flex: 0 0 45px;
  text-align: center;
  font-size: $FontSizeSmall;
}

.groups .grid .row > span.groupname {
  flex: 1 1 314px;
}

.groups .grid .row > span.location {
  flex: 1 1 200px;
}

.groups .grid .row > span.grouptype {
  flex: 1 1 200px;
}

.groups .grid .row > span.grouptype {
  flex: 1 1 200px;
}

.groups .grid .row > span.groupid {
  flex: 0 1 100px;
}

.groups .grid .row > span.send {
  flex: 0 0 30px;
  padding: 0;
}

.groups .grid .row > span.actions {
  flex: 0 0 54px;
  text-align: center;
  overflow: initial;
}

/* Group Name cell */
.groups .grid .items .row > span {
  line-height: 59px;
}

.groups .grid .items .row > span.groupname {
  padding-top: 9px;
  line-height: 22px;
}

.groups .groupname span {
  display: block;
  color: $DimPrimaryColor;
  font-size: $FontSizeTiny;
  line-height: 18px;
}

.groups .grid .header-row .groupname {
  margin-left: 45px;
}

/* Actions menu */
.groups .grid .actions > i {
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
}

.groups .actions-menu .popover {
  text-align: left;
  min-width: 116px;
  margin-top: -16px;
}

/* Mobile Filter Bar */
.mobile-filter-bar {
  height: 65px;
  line-height: 65px;
  text-align: right;
  border-bottom: 1px solid $LightBorder;
}

.mobile-filter-bar > * {
  line-height: normal;
  text-align: left;
}

.mobile-filter-bar i.fa-filter {
  cursor: pointer;
  margin-right: 12px;
  width: 30px;
  opacity: 0.7;
}

.initials {
  text-transform: uppercase;
}

.grid .row > span {
  width: 60px;
}

.flyover-container .modal-content {
  height: 100%;
  width: 20%;
  display: block; /* or 'flex' */
  box-sizing: border-box;
}

.del-buttons {
  display: flex;
  justify-content: space-between;
}

.page-layout-content-header .search-box {
  width: 360px;
  margin-right: 24px;
  height: 40px;
}

.gui-btn.btn-outline.filter-button {
  height: 40px;
  margin-right: 16px;
}

.tools {
  display: flex;
}

.gui-btn.btn-text.columns-cog {
  float: right;
}

/* Mobile Items */
.groups .mobile-actions {
  margin-right: 32px;
}
