@import "vars.scss";

.template-picker {
  h2 {
    margin: 0 0 30px 0;
    font-size: 16px;
    color: $TextColor;
  }

  .preview {
    margin: 30px 0 60px 0;
    overflow: auto;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    background: $ContentBackground;
    padding-top: 10px;
  }

  .btn.insert {
    float: right;
  }
}

.template-picker-modal .modal {
  width: 50vh;
  height: fit-content;
  position: relative;

  .modal-content {
    height: 100%;
  }
}
