.toggle-bar {
  display: flex;
  background-color: #ededed;
  border-radius: 6px;
  padding: 4px;
  width: 120px;

  .tab {
    flex: 1;
    padding: 10px;
    border: none;
    color: #6b6b6b;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
  }

  .active {
    background-color: #fff;
    padding: 4px 10px 4px 10px;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: #363636;
    border-radius: 6px;
  }
}
