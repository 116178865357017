@import "vars.scss";
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: 0px 2px 16px 0px rgba(78, 92, 105, 0.04);
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #d8d8d8;
}
.Modal {
  display: none;
  position: fixed;
  background-color: #fff;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-out;
  z-index: 10;
}

.Modal.Show {
  position: fixed;
  right: 0;
  width: 390px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media ($IsMobile) {
    width: 100%;
  }
}
.Modal.hide {
  display: none;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ($IsMobile) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  padding: 27px 20px 16px 32px;
  h4 {
    color: $TextColor;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
  }
  .close-primary-icon {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    color: $TextSecondary;
  }
}
.gm-modal-footer {
  &-hr-divider {
    border-bottom: 1px solid $DimHover;
  }
}
.gm-modal-body {
  flex-grow: 1;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: 0px 2px 16px 0px rgba(78, 92, 105, 0.04);
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #d8d8d8;
  }
  padding: 0 32px 22px 32px;
  .gm-modal-title {
    display: flex;
    margin: 16px 0;
    &-avatar {
      .initials {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: rgba(var(--stroke-low), 1);
        color: rgba(var(--text-primary), 1);
        font-size: 12px;
        line-height: 40px;
        text-align: center;
        font-weight: 600;
      }
    }
    &-name-desc {
      display: flex;
      flex-direction: column;
      padding: 0px 8px;
      .gm-modal-title-name-group {
        color: $TextColor;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
      }
      .gm-modal-title-desc-group {
        color: $TextColor;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
  &-content {
    margin: 16px 0;
    &-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      &-name {
        color: $TextColor;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
      &-number {
        color: $TextSecondary;
        text-align: right;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
      }
    }
    &-list {
      max-height: 57vh;
      overflow-y: scroll;
      overflow-x: hidden;
      .gm-modal-content-list-title {
        display: flex;
        margin: 8px 0;
        align-items: center;
        &-name {
          margin-left: 16px;
          color: $TextColor;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
        }
      }
    }
  }
}
.modal-body {
  padding: 0 32px 22px 32px;
  flex-grow: 1;
  overflow-y: auto;

  &-title {
    margin-bottom: 16px;
    color: $TextColor;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  &-header {
    margin: 13px 0 16px 0;
    display: flex;
    width: 100%;
    &-avatar {
      margin-right: 16px;
      .initials {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: rgba(var(--stroke-low), 1);
        color: rgba(var(--text-primary), 1);
        font-size: 12px;
        line-height: 40px;
        text-align: center;
        font-weight: 600;
      }
    }
    &-title {
      flex: 1;
      &-name {
        display: flex;
        justify-content: space-between;
        .name {
          color: $TextColor;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
        }
        .time {
          color: $TextSecondary;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          margin-left: 12px;
        }
      }
      .receiver-name {
        color: $TextSecondary;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}

.rm-modal-footer {
  height: 80px;
  &-hr-divider {
    border-bottom: 1px solid $GridHeaderBottomBorder;
  }

  &-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    padding: 16px 16px 24px 16px;
    align-items: flex-end;

    .add-btn {
      margin-left: 12px;
      height: 40px;
      width: 163px;
      border: none;
      border-radius: 4px;
      background-color: #1863cc;
      color: $TextColorInverted;
    }

    .cancel-btn {
      color: $TextSecondary;
      height: 40px;
      width: 100px;
      border: 1px solid $DimPrimaryColor;
      border-radius: 4px;
      background-color: $TextColorInverted;
    }
  }
}
