@import "../vars.scss";
.gui-tooltip {
  background: var(--color-dark-background);
  color: $TextColorInverted;
  padding: 10px;
  border-radius: 5px;
  margin-left: 24px;
  z-index: 10000;
  max-height: none; // Prevent full-height issue
  display: inline-block; // Prevent tooltip from taking full height
  position: relative;

  // Arrow common styling
  &::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
  }

  // Right placement  - Arrow pointing horizontally to the left
  &[x-placement="right"]::before {
    border-width: 5px 5px 5px 0;
    border-color: transparent var(--color-dark-background) transparent
      transparent;
    top: 50%;
    left: -5px; // Adjust arrow to the left of the tooltip
    transform: translateY(-50%); // Center the arrow vertically
  }

  // Top placement
  &[x-placement="top"]::before {
    border-width: 5px 5px 0 5px;
    border-color: var(--color-dark-background) transparent transparent
      transparent;
    bottom: -5px;
    left: 43%;
  }
}
