@import "vars.scss";

.my-groups-divider {
  border-top: 1px solid #ededed;
}
.go-to-groups-icon {
  margin-left: 12px;
}
.my-groups-container {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 24px;
  height: fit-content;
  background-color: #fff;
  @media ($IsMobile) {
    margin: 0;
    max-width: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;

    &-title {
      color: #363636;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .groups-list {
    margin-top: 15px;
    border-bottom: 1px solid #ededed;
  }
  .icon-primary {
    color: #757575;
    font-size: 12px;
    cursor: pointer;
  }
  .go-to-groups-btn {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #6b6b6b;
    text-align: center;
    cursor: pointer;
  }
}
.avatar {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ededed;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  .initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #ededed;
    color: black;
    border-radius: 50%;
    font-size: 14px;
  }
}
.flex-wrap-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.my-groups-column {
  margin: 4px;
  padding: 16px;
}
.col-1 {
  flex: 0;
  margin: 12px;
}

.col-2 {
  flex: 2;
}

.col-3 {
  flex: 1;
}

.col-4 {
  flex: 2;
}
.col-5 {
  flex: 0;
}
.my-groups-group-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #363636;
}
.my-groups-group-members {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #1863cc;
  cursor: pointer;
}
.my-groups-group-description {
  color: #363636;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
