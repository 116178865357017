.Modal {
  display: none;
  position: fixed;
  background-color: #fff;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-out;
  z-index: 10;
}

.Modal.Show {
  display: block;
  right: 0;
  width: 390px;
  height: 93vh;
  max-height: 93vh;
  overflow-y: auto;
  @media (max-height: 800px) {
    height: 90vh;
    max-height: 90vh;
  }
}
.Modal.hide {
  display: none;
}

.Close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 10px;
  background-color: transparent;
  border: 0;
  font-size: 18px;
}
.edit-profile {
  h4 {
    color: #363636;
    font-size: 16px;
    font-weight: 600;
  }
  .close-primary-icon {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    color: #6b6b6b;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 12px;
  }
  &-body {
    padding: 0 20px 12px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;

    &-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: #363636;
    }
    &-image {
      margin-top: 12px;
      width: 349px;
      height: 165px;
      border: 1px solid #ededed;
      border-radius: 12px;

      position: relative;
      text-align: center;
      .background-curve {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 106px;
        background: linear-gradient(
          to top,
          rgba(212, 211, 209, 0.5),
          rgba(255, 255, 255, 0)
        );
        clip-path: ellipse(75% 90% at 54% 10%);
      }
      .profile-image-container {
        position: relative;
        margin-top: 16px; // Push the image into the curved background
        display: inline-block;
        // for profile image
        .edit-icon {
          position: absolute;
          bottom: 2px;
          left: 89px;
          width: 32px;
          height: 32px;
          background-color: #757575;
          border-radius: 50%;
          border: 2px solid #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

          i {
            font-size: 14px;
            font-weight: 900;
            color: #fff;
          }
        }
        .profile-image {
          width: 123px;
          height: 123px;
          border-radius: 50%;
          border: 5px solid white; // Creates a white border around the image
          object-fit: cover;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
        }
        .initials {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 123px;
          height: 123px;
          background-color: #d6d0d0;
          color: black;
          border-radius: 50%;
          font-size: 26px;

          border: 5px solid white; // Creates a white border around the image
          object-fit: cover;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
        }
      }
    }

    &-textbox {
      margin-top: 22px;
      input {
        width: 100%;
      }
    }
    &-select {
      margin: 22px 0;
    }
    .user-type-name {
      color: #363636;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    .role-badge-faculty {
      background-color: #f1f9f3;
      color: #3ca455;
      border: none;
      border-radius: 6px;
      padding: 0 6px;
      font-size: 11px;
      font-weight: 600;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    .role-badge-student {
      background-color: #fcece4;
      color: #cd4d0b;
      border: none;
      border-radius: 6px;
      padding: 0 6px;
      font-size: 11px;
      font-weight: 600;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
  }
  .hr-divider {
    border-bottom: 1px solid #d2d2d2;
  }
  &-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    padding: 16px;
    align-items: flex-end;

    .update-btn {
      margin-left: 12px;
      height: 40px;
      width: 100px;
      border: none;
      border-radius: 4px;
      background-color: #1863cc;
      color: #ffffff;
    }
    .update-btn :disabled {
      background-color: #a4c1e8;
    }
    .cancel-btn {
      color: #6b6b6b;
      height: 40px;
      width: 100px;
      border: 1px solid #a6a6a6;
      border-radius: 4px;
      background-color: #ffffff;
    }
  }
}
