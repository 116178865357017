@import "vars.scss";
.display-flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}

/* Title */
.page-layout-header {
  justify-content: space-between;
  background: $ContentBackground;
  box-shadow: $HeaderShadow;
  padding: 16px 0 16px 16px;
}
.page-layout-header h3 {
  align-self: center;
  margin: 0;
  font-size: var(--font-size-large);
  font-weight: 400;
  line-height: 24px;
  color: #363636;
}
/* Top Buttons */
.page-layout-top-buttons {
  display: flex;
  align-items: flex-end;
}
.page-layout-top-buttons button {
  margin: 0 18px 0 0;
  font-size: $FontSizeSmall;
}
/* Tabs */
.page-layout-tab-bar {
  clear: both;
  padding: 0 0;
  margin-top: 10px;
}
.page-layout-tabs span {
  display: inline-block;
  padding: 3px 20px 0 20px;
  font-size: $FontSizeSmall;
  line-height: 41px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
}
.page-layout-tabs span.active {
  font-weight: 600;
  letter-spacing: -0.3px;
  border-color: $PrimaryColor;
}
/* Content */
.page-layout-content-header {
  padding: 24px 24px 12px 24px;

  .search-box {
    width: 360px;
    margin-right: 24px;
  }
  .tools {
    float: right;
  }
}
.progress-container {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  .progress-container-text {
    color: #6b6b6b;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
  }
  .progress-container-text-negative-credit {
    color: #c54443;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    i {
      font-size: 18px;
    }
  }

  .styled-progress-bar {
    width: 100%;
    height: 6px;
    appearance: none;
    border-radius: 2px;
    background: #ececee;
    overflow: hidden;
    border: 1px solid #ececee;
  }

  /* Styling for the progress bar value */
  .styled-progress-bar::-webkit-progress-value {
    background: #e67337;
    border-radius: 2px;
  }
  .styled-progress-bar::-webkit-progress-bar {
    background: #fff; /* Set the unfilled part to white */
    border-radius: 2px;
  }
  /* For Firefox */
  .styled-progress-bar::-moz-progress-bar {
    background: #e67337;
    border-radius: 2px;
  }

  /* For other browsers */
  .styled-progress-bar::-ms-fill {
    background: #e67337;
    border-radius: 2px;
  }
  .negative-credits::-webkit-progress-value {
    background: #c84040;
    border-radius: 2px;
  }
  /* For Firefox */
  .negative-credits::-moz-progress-bar {
    background: #c84040;
    border-radius: 2px;
  }

  /* For other browsers */
  .negative-credits::-ms-fill {
    background: #c84040;
    border-radius: 2px;
  }
}
@media ($IsMobile) {
  /* Mobile Header */
  .page-layout-header {
    box-shadow: none;
  }

  /* Mobile Search */
  .page-layout-header i.fa-search {
    margin-right: 10px;
    padding: 12px;
    font-weight: 900;
    color: var(--color-text-lighter);
    opacity: 0.7;
  }

  .mobile-search h3 {
    color: transparent;
  }

  .mobile-search .page-layout-top-buttons {
    display: none;
  }

  .mobile-search .page-layout-mobile-search-bar {
    position: absolute;
    padding-left: 16px;
    line-height: 68px;
  }

  .mobile-search .search-box {
    padding-left: 0;
  }

  .mobile-search input {
    width: 150px;
  }

  .mobile-search i.fa-search {
    margin-right: 0;
  }

  .mobile-search .cancel {
    display: block;
    float: right;
    padding: 0 30px 0 10px;
    color: $PrimaryColor;
    font-weight: 600;
    cursor: pointer;
  }

  /* Tab bar */
  .page-layout-tab-bar {
    padding: 0;
    line-height: 64px;
    text-align: center;
    border-bottom: 1px solid $LightBorder;
  }

  .page-layout-tabs {
    display: inline-block;
    padding: 3px;
    line-height: 1em;
    border-radius: 4px;
    background: var(--color-gray-medium-background);
  }

  .page-layout-tabs > span {
    padding: 4px 10px;
    color: var(--color-text-lighter);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    border-bottom: 0;
  }

  .page-layout-tabs > span.active {
    color: var(--color-text-lighter-opacity-70);
    background-color: $ContentBackground;
    border-radius: 4px;
  }

  /* Mobile Body */
  .page-layout-body > .card {
    margin: 0;
    box-shadow: none;
    border-radius: 0;
  }
}
