@import "vars.scss";

* {
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
  font-size: $FontSizeNormal;
  background: $PageBackground;
  margin: $TopBarHeight 0 0 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none; /* Disable pull down to refresh */
  @include disableHilighting;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: black !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

@media ($IsMobile) {
  .desktop-only {
    display: none !important;
  }
}

@media ($IsDesktop) {
  .mobile-only {
    display: none !important;
  }
}

.page-content {
  padding-left: $SidebarCollapsedWidth;

  &.expanded-side-bar {
    padding-left: $SidebarWidth;

    @media ($IsMobile) {
      padding-left: 0px;
    }
    @media ($IsTablet) {
      padding-left: 0px;
    }
  }

  @media ($IsMobile) {
    padding-left: 0px;
  }
  @media ($IsTablet) {
    padding-left: 0px;
  }
}

.page-title {
  padding: 13px 0;
  background: $ContentBackground;
  color: $TextColor;
  font-size: 18px;
  font-weight: 600;
  box-shadow: $BoxShadow;
  text-align: center;

  @media ($IsDesktop) {
    text-align: left;
    padding-left: 25px;
    font-size: 28px;
    line-height: 60px;

    .nav-button {
      opacity: 0.8;
      margin: 0 0 8px 11px;

      &:hover {
        opacity: 1;
      }
    }
  }

  .nav-button {
    display: block;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;

    @media ($IsMobile) {
      position: absolute;
      left: 12px;
      width: 24px;
      height: 24px;
    }

    i {
      margin-right: 11px;
    }
  }
}

.container-card {
  background: $ContentBackground;
  box-shadow: $BoxShadow;
  padding: 0 15px;

  @media ($IsDesktop) {
    margin: 16px $ContentLeftPadding;
    border-radius: 4px;
  }
}

.loading-page {
  position: fixed;
  top: $TopBarHeight;
  left: $SidebarCollapsedWidth;
  right: 0;
  bottom: 0;
  background: rgba(200, 200, 200, 0.4);
  z-index: $BlackoutZ;

  i {
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -10px;
    font-weight: 600;
  }

  @media ($IsMobile) {
    left: 0;
  }
}

.expanded-side-bar .loading-page {
  left: $SidebarWidth;
}

.btn {
  background: $PrimaryColor;
  font-family: var(--font-family);
  color: $TextColorInverted;
  border: 1px solid var(--color-gray-border);
  border-radius: 4px;
  padding: 5px 31.5px;
  font-weight: 200;
  font-size: 14px;
  cursor: pointer;
}

.btn-outline {
  background: $ContentBackground;
  color: var(--color-text-lighter);
}

.box-card {
  border-radius: 10px;
  font-size: 14px;
  margin-top: 10px;
  overflow: hidden;
  border: 1px solid var(--color-lighter-gray-border);

  .box-subject {
    padding: 15px 14px;
    border-bottom: 1px solid var(--color-lighter-gray-border);
    background: var(--color-email-subject-background);

    > label {
      font-weight: 600;
    }
  }

  .box-body {
    padding: 4px 14px 28px 14px;
  }
}

.toast-container {
  position: fixed;
  right: 15px;
  top: 70px;
  z-index: $ToastZ;

  .toast {
    padding: 12px 45px 12px 48px;
    margin-bottom: 10px;
    color: var(--color-success);
    border: 2px solid var(--color-success);
    background: var(--color-success-background);
    border-radius: 4px;
    font-size: 16px;
    position: relative;

    .toast-icon {
      position: absolute;
      left: 16px;
      top: 17px;
      font-weight: 900;
    }

    .fa-times {
      position: absolute;
      right: 19px;
      top: 14px;
      margin-top: 4px;
      cursor: pointer;
    }

    &.removed,
    &.error {
      color: $Warning;
      border-color: $Warning;
      background: var(--color-warning-background);
    }
  }
}

.icon-button {
  border-radius: $SmallRoundedCorners;
  padding: 4px 8px;
  cursor: pointer;

  &:hover {
    background: $DimHover;
  }
}

.separator-dot {
  display: inline-block;
  width: 2px;
  height: 2px;
  background: $TextColor;
  border-radius: 1px;
  margin: 2px 0.5em;
}

.initials {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: $TopBarUserIconBackground;
  color: $TextColor;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
  font-weight: 600;
}
