@import "vars.scss";

.edit-multiple-count {
  font-size: $FontSizeSmall;
  color: $TextSecondary;
  margin-right: 16px;
  display: flex;
  align-items: center;
}

.edit-multiple-button {
  padding: 8px 16px;

  > i {
    transition: transform $AnimationLength ease;
  }

  &.active > i {
    transform: rotate(-180deg);
  }
}

.edit-multiple-popover {
  &.popover-anchor {
    display: inline-block;
  }
  .popover {
    right: 0;
    left: auto;
    top: calc(100% + 3px);
    width: fit-content;
    min-width: 20vh;
    padding: 36px 24px 24px 24px;
  }
}
