.message-dropdown {
  position: relative;
  display: inline-block;
  .dropdown-button {
    background-color: #1863cc;
    color: #ffffff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 90px;
    i {
      margin-left: 8px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 4px;
    width: 142.7px;
    z-index: 9;

    .dropdown-item {
      padding: 8px 14px;
      cursor: pointer;

      &:hover {
        background-color: #d2e0f3;
      }

      i {
        margin-right: 8px;
        color: #757575;
        text-align: center;
        font-size: 16px;
        font-weight: 900;
        line-height: 100%;
      }
      span {
        color: #363636;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
