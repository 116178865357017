@import "vars.scss";
.Modal {
  display: none;
  position: fixed;
  background-color: #fff;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-out;
  z-index: 10;
}

.Modal.Show {
  position: fixed;
  right: 0;
  width: 390px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media ($IsMobile) {
    width: 100%;
  }
}
.Modal.hide {
  display: none;
}
.error-class {
  margin: 12px 0;
  color: red;
  font-size: 14px;
}
.mr-2 {
  margin-right: 8px;
}
.add-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ($IsMobile) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  padding: 18px 20px 0 32px;
  h4 {
    color: #363636;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
  }
  .close-primary-icon {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    color: #6b6b6b;
  }
}
.add-modal-body {
  padding: 32px;
  flex-grow: 1;
  overflow-y: auto;

  .delete-header {
    color: #363636;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .delete-title {
    margin-top: 18px;
    color: #363636;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .helper-text {
    color: #6b6b6b;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  .contact-email {
    margin-top: 24px;
    input {
      width: 100%;
    }
  }
  .phone-num-ext {
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
    .phone-number {
      > label {
        color: #6b6b6b;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
      input {
        width: 215px;
      }
    }
    .phone-ext {
      > label {
        color: #6b6b6b;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
      input {
        width: 90px;
      }
    }
  }
  .contactSelect {
    margin-top: 24px;
    > label {
      // font-size: 14px;
      font-size: 16px;
      color: #363636;
      font-weight: 400;
    }
  }
  .address-field {
    margin: 24px 0;
    &-row {
      margin: 22px 0 0 0;
      input {
        width: 100%;
      }
    }
  }
  .contact-checkboxes {
    margin: 24px 0;
    .checkbox-row {
      margin: 22px 0 0 0;
    }
  }
  .preferred-language {
    margin-top: 22px;
  }
  .contact-status {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    &-header {
      color: #363636;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    &-body {
      display: flex;
      flex-direction: column;
      > label {
        margin-top: 16px;
        color: #363636;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }
  }
}
.modal-body-delete {
  padding: 0 32px 22px 32px;
  .delete-header {
    color: #363636;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  .delete-title {
    margin-top: 18px;
    color: #363636;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.modal-footer {
  height: 80px;
  &-hr-divider {
    border-bottom: 1px solid #d2d2d2;
  }
  &-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    padding: 16px;
    align-items: flex-end;

    .add-btn {
      margin-left: 12px;
      height: 40px;
      width: 100px;
      border: none;
      border-radius: 4px;
      background-color: #1863cc;
      color: #ffffff;
    }
    .update-btn :disabled {
      background-color: #a4c1e8;
    }
    .cancel-btn {
      color: #6b6b6b;
      height: 40px;
      width: 100px;
      border: 1px solid #a6a6a6;
      border-radius: 4px;
      background-color: #ffffff;
    }
    .del-btn {
      color: #c54443;
      height: 40px;
      width: 100px;
      border: 1px solid #c54443;
      border-radius: 4px;
      background-color: #ffffff;
      span {
        margin-left: 6px;
      }
    }
    .delete-button {
      color: #ffffff;
      height: 40px;
      width: 100px;
      border: 1px solid #c54443;
      border-radius: 4px;
      background-color: #c54443;
      margin-left: 16px;
    }
  }
}
