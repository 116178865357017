@import "../vars.scss";

.gui-btn {
  color: $TextColorInverted;
  background: $PrimaryColor;
  font-family: var(--font-family);
  font-size: $FontSizeNormal;
  line-height: 22px;
  font-weight: 600;
  border: 1px solid $PrimaryColor;
  border-radius: $SmallRoundedCorners;
  padding: 5px 12px;
  min-width: 90px;
  cursor: pointer;

  > i.fa-plus {
    margin-right: 8px;
  }

  > i.fa-chevron-down {
    margin-left: 8px;
  }

  &:hover {
    border-color: var(--color-primary-hover);
    background: var(--color-primary-hover);
  }

  &:active {
    border-color: var(--color-primary-actve);
    background: var(--color-primary-actve);
  }
  //
  // Outline
  &.btn-outline {
    color: var(--color-text-lighter);
    background: $ContentBackground;
    border: 1px solid $OutlineButtonBorder;

    &:hover {
      background: $SecondaryButtonHover;
    }

    &:active,
    &.active {
      color: $TextColor;
      background: var(--color-secondary-button-active);
    }
  }
  //
  // Text button
  &.btn-text {
    color: var(--color-text-lighter);
    background: $ContentBackground;
    border: 1px solid transparent;

    &:hover {
      background: $SecondaryButtonHover;
    }

    &:active {
      color: $TextColor;
      background: var(--color-secondary-button-active);
    }
  }
  //
  // Warning
  &.warning {
    background: $Warning;
    border-color: $Warning;

    &:hover {
      background: var(--color-warning-hover);
      border-color: var(--color-warning-hover);
    }

    &:active {
      background: var(--color-warning-active);
      border-color: var(--color-warning-active);
    }
  }

  &.disabled {
    opacity: 0.35;
    //diabled code to update hover notification for disabled email contact button in add people
    //pointer-events: none;
  }
}
