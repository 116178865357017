@import "vars.scss";

.d-flex-row {
  display: flex;
  flex-direction: row;
}
.word-break {
  word-break: break-all;
}
.ml-2 {
  margin-left: 8px;
}
.cursor-pointer {
  cursor: pointer;
}
.cancel-edit-active {
  background-color: #e1e1e1 !important;
  color: #363636 !important;
}
.loader-error {
  display: flex;
  align-items: center;
  justify-content: center;
  @media ($IsMobile) {
    min-height: 90vh;
  }
  @media ($IsTablet) {
    min-height: 90vh;
  }
}
.profile-card::-webkit-scrollbar {
  display: none;
}

.profile-card {
  background: #fff;
  border-radius: 12px;
  padding: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 35%;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;

  .circular-progress {
    height: 80vh;
  }
  @media ($IsMobile) {
    max-width: none;
  }
  @media ($IsTablet) {
    max-width: none;
  }
  .org-name {
    display: flex;
    justify-content: center;
    align-items: baseline;
    i {
      font-size: 14px;
      font-weight: 900;
      color: #757575;
      margin-right: 8px;
    }
  }
  .profile-header {
    position: relative;
    text-align: center;

    .background-curve {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 106px;
      background: linear-gradient(
        to top,
        rgba(212, 211, 209, 0.5),
        rgba(255, 255, 255, 0)
      );
      clip-path: ellipse(75% 90% at 54% 10%);
    }

    .profile-image-container {
      position: relative;
      margin-top: 20px; // Push the image into the curved background
      display: inline-block;
      // for profile image
      .profile-image {
        width: 126px;
        height: 126px;
        border-radius: 50%;
        border: 8px solid white; // Creates a white border around the image
        object-fit: cover;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
      }
      .initials {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 123px;
        height: 123px;
        background-color: #d6d0d0;
        color: black;
        border-radius: 50%;
        font-size: 26px;
        border: 5px solid white;
        object-fit: cover;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .profile-info {
      .profile-name {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }
      h4 {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        margin: 0;
      }
      .organization {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        color: #6b6b6b;
        margin-top: 4px;
      }
    }

    .edit-icon {
      margin-left: 24px;
      font-size: 12px;
      color: #757575;
    }
  }
  .profile-card-body {
    padding: 0 24px 24px 24px;
  }
  .profile-horizontal-divider {
    border-bottom: 1px solid #ededed;
    margin: 24px 0;
  }
  .permissions,
  .primary-contact,
  .contact-methods,
  .preferences {
    margin-bottom: 16px;
    .section-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 8px;
    }
    i {
      font-size: 14px;
      font-weight: 900;
      color: #757575;
    }
    .section-body-title {
      color: #363636;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      margin-left: 8px;

      word-wrap: break-word;
      width: 45%;
      text-align: right;
    }
    .section-body {
      display: flex;
      justify-content: space-between;
    }
  }
  .contact-methods {
    .section-body {
      margin-bottom: 8px;
    }
    .section-title-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .add-edit-btns {
      display: flex;
      > :last-child {
        margin-left: 8px;
      }
    }
    .add-edit-contact {
      color: #6b6b6b;
      background-color: #fff;
      border: 1px solid #a6a6a6;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      i {
        margin-right: 8px;
      }
    }
  }
  .role-badge {
    background-color: #e6effe;
    color: #1863cc;
    border: none;
    border-radius: 6px;
    padding: 0 6px;
    font-size: 11px;
    font-weight: 600;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .preferences {
    .section-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .section-body {
      margin-bottom: 8px;
    }
    .add-edit-contact {
      color: #6b6b6b;
      background-color: #fff;
      border: 1px solid #a6a6a6;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      i {
        margin-right: 8px;
      }
    }
  }
}
