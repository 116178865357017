@import "vars.scss";

.add-person .expand-panel:first-child {
  border-top-left-radius: $LargeRoundedCorners;
  border-top-right-radius: $LargeRoundedCorners;
}

.add-person .expand-panel:last-child {
  border-bottom-left-radius: $LargeRoundedCorners;
  border-bottom-right-radius: $LargeRoundedCorners;
}
.d-flex {
  display: flex;
}
.mt-3 {
  margin-top: 16px;
}
.row {
  display: flex;
  @media ($IsMobile) {
    flex-direction: column;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    flex-direction: column;
  }

  &.last {
    margin-bottom: 20px;
  }
}

.top-bar .help {
  @media ($IsMobile) {
    padding-left: 40px;
  }
}
.top-bar .settings-cog {
  @media ($IsMobile) {
    padding-left: 30px;
  }
}
.add-person .gui-textbox {
  margin: 24px 0 0 24px;

  @media ($IsMobile) {
    margin: 24px 0 0 0;
    input {
      width: 100%;
    }
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    input {
      width: 100%;
    }
  }
  @media (min-width: 1200px) {
    input {
      width: 420px;
    }
  }

  &.address {
    @media ($IsMobile) {
      input {
        width: 100%;
      }
    }
    @media (min-width: 768px) and (max-width: 1200px) {
      input {
        width: 100%;
      }
    }
    @media (min-width: 1200px) {
      input {
        width: 865px;
      }
    }
  }
}

.add-person .gui-select {
  margin: 24px 0 0 24px;
  @media ($IsMobile) {
    margin: 24px 0 0 0;
  }
  .search-select {
    @media ($IsDesktop) {
      width: 41vh;
    }
    width: 100%;
  }
}

.add-person {
  .nxtBar {
    display: flow-root;
  }

  .nxt-btn {
    float: right;
    width: 100px;
  }

  .add-btn {
    margin: 19px 0 0 24px;
    color: #1863cc;
    border: none;
  }

  .add-btn-phone {
    margin: 48px 0 0 24px;
    color: #1863cc;
    border: none;
  }

  .removable-section {
    position: relative;
    .search-select {
      @media ($IsDesktop) {
        width: 16vh;
      }
      width: 100%;
    }

    .section-title {
      margin: 0;
      position: absolute;
      left: 26px;
      top: -5px;
    }

    .phone input {
      width: 152px;
      @media ($IsMobile) {
        width: 100%;
      }
    }

    .ext input {
      width: 64px;
      @media ($IsMobile) {
        width: 100%;
      }
    }

    i.far.fa-xmark-large {
      margin: 35px 0 0 17px;
    }
  }
}

.bottom-button-bar {
  padding: 0 40px;
  float: right;
  display: flex;

  .last-btn {
    margin-left: 16px;
  }
  @media ($IsMobile) {
    padding: 24px;
    justify-content: space-between;
    float: none;
  }
}

.validate-user {
  margin: 24px 0 0 24px;
}

.contact-select {
  height: 40px;
  margin-top: 24px;
  border-radius: 4px;
  margin-left: 16px;
}

.resizer-content {
  position: relative;
}

.username-exists {
  color: red;
}

.valid-username {
  color: green;
}
.user-helper-text {
  margin: 0 0 0 30px;
  @media ($IsMobile) {
    margin: 0;
  }
}
.password-validation {
  display: flex;
  margin-top: 16px;
}
.password-row {
  margin: 0px 60px 0px 24px;
}

.required-info {
  font-size: small;
  margin-left: 24px;
}

.section-title-email {
  margin: 24px 0 -12px 0;
  position: unset;
  top: 0;
  @media ($IsDesktop) {
    margin-left: 22px;
    position: relative;
    top: 32px;
  }
}
.section-title-phone {
  margin: 24px 0 -12px 0;
  position: unset;
  top: 0;
  @media ($IsDesktop) {
    margin-left: 22px;
    position: relative;
    top: 32px;
  }
}

.custom-group.removable-section .row {
  margin-bottom: 24px;
}
.custom-group .gui-select .search-select {
  @media ($IsMobile) {
    width: 30vh;
  }
}
.phone-number-section {
  margin-bottom: 47px;
  @media ($IsMobile) {
    margin-bottom: 24px;
  }
}
