.text-base-primary {
  color: #363636;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.edit-preference {
  .modal-body {
    padding: 12px 32px 22px 32px;
    .email-checkbox {
      margin: 16px 0;
      .checkbox-row {
        margin: 22px 0 0 0;
      }
    }
    .email-field {
      margin: 24px 0;
      &-row {
        margin: 22px 0 0 0;
        input {
          width: 100%;
        }
        .has-email-error {
          label {
            color: #c54443;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
          }
        }
        label {
          color: #363636;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
      .helper-text {
        color: #6b6b6b;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
    .voice-preference {
      margin-top: 38px;
      h4 {
        color: #363636;

        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
      &-dropdown {
        button {
          margin-left: 0;
        }
        span {
          color: #6b6b6b;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }
      .voice-sample {
        margin-top: 22px;
        span {
          color: #363636;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }

      .loader {
        width: 30px;
        height: 30px;
      }

      &-dropdown {
        button {
          width: 100%;
        }
        .dropdown-menu {
          width: 83%;
          z-index: 999;
        }
        .dropdown-container-comm {
          width: 100%;
          display: flex;
          flex-direction: column;
          button {
            text-align: start;
          }
        }
      }
    }
  }
}
