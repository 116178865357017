@import "vars.scss";
.slider-search-container {
  position: relative;
}

.slider-search-box {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--color-search-icon-text);
  padding: 0 12px;
  border: 1px solid $InputBorderColor;
  border-radius: $SmallRoundedCorners;
  white-space: nowrap;
  max-height: 40px;

  @media ($IsMobile) {
    width: 252px;
    min-width: 0%;
    max-height: 32px;
  }
  @media ($IsDesktop) {
    min-width: 434px;
  }
}

.slider-search-box input {
  flex: 1;
  padding: 8px;
  font-size: 16px;
  font-family: var(--font-family);
  color: $TextColor;
  background: transparent;
  border: none;
  outline: none;

  &::placeholder {
    color: var(--color-search-icon-text);
  }
}

.slider-search-box .search-icon {
  margin-right: 8px;
  color: var(--color-text-lighter);
}

.slider-search-box .filter-icon {
  cursor: pointer;
  margin-right: 8px;
  color: var(--color-text-lighter);
}
.slider-dropdown {
  position: relative;
  display: inline-block;
  top: -18px;

  .slider-dropdown-menu {
    position: absolute;
    left: 0;

    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 24px;
    @media ($IsMobile) {
      width: 252px;
    }
    @media ($IsDesktop) {
      width: 433px;
    }
    &-btns {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      .clear-btn {
        color: #6b6b6b;
        background-color: #fff;
        border: none;
      }
    }
    &-item {
      margin-bottom: 24px;
      input {
        width: 100%;
      }

      &-header {
        color: #363636;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
}
