@import "vars.scss";

.filter-button {
  padding: 8px 16px;
  color: #363636;

  > i {
    transition: transform $AnimationLength ease;
  }

  &.active > i {
    transform: rotate(-180deg);
  }
}

.filter-popover {
  &.popover-anchor {
    display: inline-block;
  }

  .popover {
    min-width: 20vh;
    width: fit-content;
    padding: 16px;
    top: calc(100% + 10px);
    left: calc(100% - 140px);
  }

  .gui-select {
    margin-bottom: 36px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  .btn-text {
    margin-right: 12px;
  }
}
