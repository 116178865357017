@import "vars.scss";

.confirm-close-modal {
  h4 {
    padding: 11px 0;
    margin: 0;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: $TextColor;
    border-bottom: 1px solid $LightBorder;
    background-color: $ContentBackground;
  }

  .modal-content > div {
    line-height: 60px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    border-bottom: 1px solid $LightBorder;
    background-color: $ContentBackground;
    cursor: pointer;
  }

  .delete {
    color: $Warning;
  }

  .detail-delete {
    color: $Warning;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .save-draft {
    color: $PrimaryColor;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .disabled {
    opacity: 0.35;
  }

  .cancel {
    color: $PrimaryColor;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    margin-top: 8px;
  }
}
