@import "vars.scss";

/* Group Properties Model */
.group-properties-modal .modal {
  @media ($IsMobile) {
    max-width: calc(100vw - 48px);
    top: 36px;
  }
}

/* Group Properties */
.group-properties .form-row {
  margin-bottom: 25px;
}

.group-properties .gui-textbox input {
  width: 100%;
}
.always-attach {
  .search-select {
    .ss__control {
      input {
        @media ($IsMobile) {
          min-height: 8vh;
        }
      }
    }
  }
}

.group-properties .group-type-row .gui-select {
  display: inline-block;
  margin-right: 22px;
  width: 279px;
}

.group-properties .group-type-row .gui-checkbox {
  display: inline-block;
  margin-top: 8px;
}

.group-properties .caller-id-row .gui-textbox {
  display: inline-block;
  margin-right: 22px;
  width: 237px;
}

/* Logo */
.group-properties .add-logo label,
.group-properties .logo label {
  display: block;
  margin: 12px 0;
}

.group-properties .add-logo button {
  margin: 0 14px 0 0;
}

.group-properties .logo img {
  margin: 0 14px 0 0;
  border: 1px solid $OldGrayMedium;
  border-radius: $XLargeRoundedCorners;
  width: 300px;
  height: 80px;
  object-fit: contain;
}

.group-properties .logo span {
  line-height: 80px;
  vertical-align: bottom;
}
