.page-layout-content-header {
  display: flex;
}

.ml-auto {
  margin-left: auto;
}

.grid .row > span.check {
  width: 4%;
}

.grid .row > span.user.sortable {
  width: 24%;
}

.grid .row > span.contact-type.sortable {
  width: 24%;
}

.grid .row > span.contact-method.sortable {
  width: 24%;
}

.grid .row > span.invalid-type.user.sortable {
  width: 24%;
}
