@import "vars.scss";

/* Modal Styling */
.cropper-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}
.cropper-modal-header {
  display: flex;
  justify-content: space-between;
}
.cropper-modal-content {
  background: white;
  border-radius: 10px;
  width: 560px;
  padding: 24px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}
.cropper-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cropper-avatar-editor {
  width: 70%;
  margin: 30px 0 12px 0;
}

/* Slider Styling */
.cropper-slider-container {
  margin: 10px auto 20px;
  width: 70%;
  i {
    font-size: 10px;
    color: #757575;
    margin: 8px;
  }
}

.custom-slider {
  width: 70%;
  background: rgba(0, 0, 0, 0.4);
  height: 4px;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  background: white;
  border: 2px solid #1863cc;
  border-radius: 50%;
  cursor: pointer;
}

.cropper-button-container {
  display: flex;
  justify-content: flex-end;
  .gui-btn {
    margin-left: 16px;
  }
  .delete-btn {
    color: #c54443;
    background-color: #ffffff;
    border: none;
  }
  .delete-btn:hover {
    background-color: #c54443;
    color: #ffffff;
  }
  .save-btn {
    color: #ffffff;
    background-color: #1863cc;
  }
}
