@import "vars.scss";

.confirm-delete-modal {
  .modal {
    text-align: center;
    color: $TextColor;
    padding: 24px;
  }

  .modal-content {
    padding: 0;
  }

  h4 {
    margin: 0 0 14px 0;
    font-weight: 700;
    font-size: 20px;
  }

  h6 {
    margin-top: 0;
    font-weight: 400;
    font-size: 14px;
  }

  .btn-container {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }

  button {
    width: 174px;

    @media ($IsMobile) {
      width: 130px;
    }
  }

  .warning {
    margin-left: 18px;
  }
}
