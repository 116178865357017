@import "../vars.scss";

.gui-date {
    position: relative;

    input.input-calendar-field {
        @include guiInput;

        &:focus {
            @include guiInputFocused;
        }
    }

    > label {
        @include guiLabel;
    }

    &.in-focus {
        > label {
            @include guiLabelFocused;
        }
    }

    &.has-value, &.in-focus {
        > label {
            @include guiLabelWithValue;
        }
    }

    &.has-error {
        > label {
            color: $Error;
        }

        input {
            border-color: $Error;
        }
    }
}


.gui-date {
    .calendar-icon {
        position: absolute;
        width: 2.5em;
        height: 2.5em;
        padding: .75em;
        margin-left: -2.5em;
        cursor: pointer;

        &:before {
            // the actual icon
        }
    }
}



// react-input-calendar
.input-calendar-wrapper {
    position: absolute;
    left: 0;
    padding: 5px 12px;
    border: 1px solid $InputBorderColor;
    box-shadow: 0 0 9px 5px rgba(0,0,0,0.05);
    background-color: $InputBackgroundColor;
    text-align: center;
    z-index: 999; //labels are 201
    color: black;
    border-radius: 4px;
    font-size: 14px;

    .navigation-wrapper {
        font-weight: 600;

        > span {
            cursor: pointer;

            &:hover {
                color: $PrimaryColor;
            }
        }

        .icon {
            float: left;

            &:last-child {
                float: right;
            }
        }
    }
    // Button size in day, month, and year pickers
    .days,
    .months,
    .years {
        width: 189px;
        padding-bottom: 2px;

        .year {
            width: 58px;
            height: 38px;
            line-height: 38px;
        }

        .month {
            width: 58px;
            height: 38px;
            line-height: 38px;
        }

        .day {
            width: 25px;
            height: 25px;
            line-height: 25px;
        }
    }

    .days-title { // Days of the week
        padding: 5px 0;

        .cell {
            height: 25px;
            line-height: 28px;
            color: black;
            font-weight: 600;
            width: 25px;
        }
    }
    // Cells
    .cell {
        display: inline-block;
        text-align: center;
        cursor: pointer;
        border: 1px solid $ContentBackground;
        border-radius: 50%;

        &:hover:not(.title) {
            color: $PrimaryColor;
            border: 1px solid $PrimaryColor;
        }

        &.current {
            background-color: $PrimaryColor;
            color: $ContentBackground !important;
            border-radius: 50%;
        }

        &.day.prev, &.day.next {
            opacity: .4; // Gray out days in the previous or next month
        }
    }
}