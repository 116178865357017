@import "vars.scss";
.messageType {
  width: 120px;
}
.recent-message-divider {
  border-top: 1px solid #ddd;
}
.go-to-message-btn {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: #6b6b6b;
  text-align: center;
  cursor: pointer;
}
.go-to-message-icon {
  margin-left: 12px;
}
.d-flex-space-between {
  display: flex;
  justify-content: space-between;
}
.role-badge-messageType.gui-btn:hover {
  background: #e1e1e1;
  border: none;
}
.role-badge-messageType {
  display: flex;
  height: 20px;
  padding: 2px 8px;
  align-items: center;
  background-color: #e1e1e1;
  border: none;
  border-radius: 12px;
  span {
    color: #363636;
    margin: 0;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
  }
  min-width: fit-content;
}
.messages-container {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 24px;
  height: fit-content;
  background-color: #fff;
  @media ($IsMobile) {
    margin: 0;
    max-width: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;

    &-title {
      color: #363636;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    .filters {
      display: flex;
      gap: 10px;
      .toggle-bar {
        margin-top: -5px;
      }

      .message-type {
        padding: 8px;
        border-radius: 5px;
        border: 1px solid #ccc;
      }
    }
  }

  .messages-list {
    margin-top: 15px;

    .message-item {
      display: flex;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #ddd;

      &:last-child {
        border-bottom: none;
      }

      .avatar {
        width: 50px;
        height: 50px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #ededed;
        color: white;
        font-size: 1.1rem;
        font-weight: bold;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        .initials {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          background-color: #ededed;
          color: black;
          border-radius: 50%;
          font-size: 1.1rem;
        }
      }

      .message-content {
        width: 100%;
        .message-preview {
          display: flex;
          justify-content: space-between;
          &:hover {
            cursor: pointer;
          }
          &-subject-isread {
            font-size: 12px;
            line-height: 20px;
            color: #1863cc;
            font-weight: 400;
          }
          &-subject-isunread {
            font-size: 12px;
            line-height: 20px;
            color: #1863cc;
            font-weight: 600;
          }
          &-time {
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            color: #6b6b6b;
          }
          &-content {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            color: #6b6b6b;
          }
        }
        .message-header {
          display: flex;
          flex-direction: column;
          &-name {
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            color: #363636;
          }

          .voice-note {
            audio {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
