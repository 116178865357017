@import "vars.scss";

.columns-cog {
  margin: 0 -6px 0 14px;
  padding: 4px 8px;
}

.column-picker {
  &.popover-anchor {
    display: inline-block;
  }
  .popover {
    right: -7px;
    left: auto;
    top: calc(100% + 3px);
    width: fit-content;
    min-width: 16vh;
    padding: 36px 24px 24px 24px;
  }
}
